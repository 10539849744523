<template lang="pug">
.absolute.bottom-5.top-5.left-5.z-30.bg-white.shadow.grid.grid-cols-2.pr-4.pl-6.pt-5.w-module(
  style='grid-template-rows: auto auto auto auto 1fr auto 1fr'
)
  .text-darkblue.col-start-1.col-end-2.text-xl.font-bold.whitespace-no-wrap.font-SourceSansPro {{ title === 1 ? $t("objects_tabs.sensors.option1") : $t("objects_tabs.sensors.option2") }}
  .flex.justify-end.col-start-2.items-center
    i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
      @click='$emit("close")'
    )
  //- Почему не должно быть кнопки?
  .col-start-1.col-end-3.flex.justify-end.mt-8
    skif-button.ml-6(
      v-if="!isNewXY && !isNewAB"
      width='24'
      @click='save'

    ) {{ $t("btn.save") }}
  .col-start-1.col-end-3.flex.mt-4
    .flex.flex-col
      p.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.sensors.lowerBorder") }}
      skif-input-number.mt-3(v-model.number='param.min_valid', width='36')
    .flex.flex-col.ml-16
      p.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.sensors.upperBorder") }}
      skif-input-number.mt-3(v-model.number='param.max_valid', width='36')
  .col-start-1.col-end-3.flex.mt-4
    .w-36
      skif-checkbox.mt-6(
        :label='$t("objects_tabs.sensors.smoothing")',
        v-model='is_smoothing'
      )
    .flex.flex-col.ml-16
      p.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.sensors.coefficient") }}
      el-slider.w-36(
        :min='5',
        :disabled='!is_smoothing',
        v-model='param.smoothing'
      )
  .col-start-1.col-end-3.overflow-y-auto(
    :style="{boxShadow: isNewXY ? '0px 0px 10px rgba(0, 0, 0, 0.1)' : 'none', padding: isNewXY ? '12px 12px' : 0}"
  )
    table.w-full.table-fixed
      thead.text-left
        tr.border-b.border-reallylightblue
          th.bg-white.top-0.sticky.z-10
            span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold {{ $t("objects_tabs.sensors.valueX") }}
          th.bg-white.top-0.sticky.z-10
            span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold {{ $t("objects_tabs.sensors.valueY") }}
          th.w-6.text-center.top-0.sticky.z-10.bg-white
            svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-all.ease-in-out.duration-200.transform(
              :class='isNewXY ? "rotate-45" : "rotate-0"',
              @click='createXY',
              width='16',
              height='16',
              fill='none',
              xmlns='http://www.w3.org/2000/svg'
            )
              path(
                fill-rule='evenodd',
                clip-rule='evenodd',
                d='M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 101.5 0v-2.5h2.5a.75.75 0 100-1.5h-2.5v-2.5z'
              )
        tr(v-if='isNewXY')
          th
            .relative.my-3
              skif-input-number(
                width='32',
                v-model.number='newXY.x',
                :error='isXMissing'
              )
              transition(name='el-zoom-in-top')
                .text-notify.text-xs.top-full.left-0.absolute(
                  v-if='isXMissing'
                ) {{ $t("objects_tabs.sensors.selectValueX") }}
          th
            .relative
              skif-input-number(
                width='32',
                v-model.number='newXY.y',
                :error='isYMissing'
              )
              transition(name='el-zoom-in-top')
                .text-notify.text-xs.top-full.left-0.absolute(
                  v-if='isYMissing'
                ) {{ $t("objects_tabs.sensors.selectValueY") }}
        tr(v-if='isNewXY')
          th
          th(:colspan='2')
            .flex.justify-end.mb-3
              skif-button(width='28', @click='addXY') {{ $t("btn.save") }}
      tbody
        tr(v-for='(xy, index) in param.calibrationXYlist', :key='index')
          td.truncate.pr-2.text-darkblue
            span.font-bold.leading-10.text-sm {{ xy.x }}
          td.truncate.pr-2.text-darkblue
            span.font-bold.leading-10.text-sm {{ xy.y }}
          td.flex.items-center.justify-center
            svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-colors.ease-in-out.duration-200(
              width='12',
              height='16',
              fill='none',
              xmlns='http://www.w3.org/2000/svg',
              @click='(event) => { event.stopPropagation(); deleteCalibration(index, "XY"); }'
            )
              path(
                fill-rule='evenodd',
                clip-rule='evenodd',
                d='M3 1H0v2h12V1H9L8 0H4L3 1zm8 3H1v10a2 2 0 002 2h6a2 2 0 002-2V4z'
              )
  .col-start-1.col-end-3.relative(
    :style="{marginTop: '24px'}"
  )
    .flex.group.items-center.cursor-pointer(
      @click='isChart = !isChart',
      v-if='isChart'
    )
      p.group-hover_text-headerText.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.transition-colors.ease-in-out.duration-500.mr-2 {{ $t("objects_tabs.sensors.show_table") }}
      svg.group-hover_fill-fillTrash.fill-annotationColor.transition-colors.ease-in-out.duration-500(
        width='17',
        height='14',
        fill='none',
        xmlns='http://www.w3.org/2000/svg'
      )
        path(
          d='M1.7 0h13.6c.45 0 .883.184 1.202.513.319.328.498.773.498 1.237v10.5c0 .464-.18.91-.498 1.237A1.676 1.676 0 0115.3 14H1.7c-.45 0-.883-.184-1.202-.513A1.776 1.776 0 010 12.25V1.75C0 1.286.18.84.498.513A1.676 1.676 0 011.7 0zm7.65 8.75v3.5h5.95v-3.5H9.35zm-7.65 0v3.5h5.95v-3.5H1.7zM9.35 3.5V7h5.95V3.5H9.35zm-7.65 0V7h5.95V3.5H1.7z'
        )

    .flex.group.items-center.cursor-pointer(
      @click='isChart = !isChart',
      v-else
    )
      p.group-hover_text-headerText.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.transition-colors.ease-in-out.duration-500.mr-2 {{ $t("objects_tabs.sensors.show_graph") }}
      svg.group-hover_fill-fillTrash.fill-annotationColor.transition-colors.ease-in-out.duration-500(
        width='16',
        height='15',
        fill='none',
        xmlns='http://www.w3.org/2000/svg'
      )
        path(
          d='M15.833 13.466H1.5V.17A.17.17 0 001.333 0H.167A.17.17 0 000 .17v14.66c0 .093.075.17.167.17h15.666a.17.17 0 00.167-.17v-1.194a.17.17 0 00-.167-.17zM3 12.102h11.167a.17.17 0 00.166-.17v-9.29c0-.153-.18-.228-.285-.121L9.667 7 7.054 4.36a.165.165 0 00-.235 0L2.88 8.4a.168.168 0 00-.048.12v3.413c0 .094.075.17.167.17z'
        )
    transition(name='fade')
      .absolute.top-10.bg-white.z-20.shadow(v-if='isChart')
        line-chart(:chart-data='chartData', :height='284', :width='386')
  .col-start-1.col-end-3.overflow-y-auto(
    :style="{boxShadow: isNewAB ? '0px 0px 10px rgba(0, 0, 0, 0.1)' : 'none', padding: isNewAB ?'12px 12px' : 0}"
  )
    table.w-full.table-fixed
      thead.text-left
        tr.border-b.border-reallylightblue
          th.bg-white.top-0.sticky.z-10
            span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold {{ $t("objects_tabs.sensors.border_bottom") }}
          th.bg-white.top-0.sticky.z-10
            span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold {{ $t("objects_tabs.sensors.coefficientA") }}
          th.bg-white.top-0.sticky.z-10
            span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold {{ $t("objects_tabs.sensors.coefficientB") }}
          th.w-6.text-center.top-0.sticky.z-10.bg-white
            svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-all.ease-in-out.duration-200.transform(
              :class='isNewAB ? "rotate-45" : "rotate-0"',
              @click='createAB',
              width='16',
              height='16',
              fill='none',
              xmlns='http://www.w3.org/2000/svg'
            )
              path(
                fill-rule='evenodd',
                clip-rule='evenodd',
                d='M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 101.5 0v-2.5h2.5a.75.75 0 100-1.5h-2.5v-2.5z'
              )
        tr(v-if='isNewAB')
          th
            .relative.my-3
              skif-input-number(
                width='28',
                v-model.number='newAB.fromValue',
                :error='isFromValueMissing'
              )
              transition(name='el-zoom-in-top')
                .text-notify.text-xs.top-full.left-0.absolute(
                  v-if='isFromValueMissing'
                ) {{ $t("objects_tabs.sensors.border_bottom") }}
          th
            .relative
              skif-input-number(
                width='28',
                v-model.number='newAB.a',
                :error='isAMissing'
              )
              transition(name='el-zoom-in-top')
                .text-notify.text-xs.top-full.left-0.absolute(
                  v-if='isAMissing'
                ) {{ $t("objects_tabs.sensors.selectA") }}
          th
            .relative
              skif-input-number(
                width='28',
                v-model.number='newAB.b',
                :error='isBMissing'
              )
              transition(name='el-zoom-in-top')
                .text-notify.text-xs.top-full.left-0.absolute(
                  v-if='isBMissing'
                ) {{ $t("objects_tabs.sensors.selectB") }}
        tr.border-b.border-reallylightblue(v-if='isNewAB')
          th
          th
          th(:colspan='2')
            .flex.justify-end.mb-3
              skif-button(width='28', @click='addAB') {{ $t("btn.save") }}
      tbody
        tr(v-for='ab in param.calibrationABlist', :key='ab.id')
          td.truncate.pr-2.text-darkblue
            span.font-bold.leading-10.text-sm {{ ab.fromValue }}
          td.truncate.pr-2.text-darkblue
            span.font-bold.leading-10.text-sm {{ ab.a }}
          td.truncate.pr-2.text-darkblue
            span.font-bold.leading-10.text-sm {{ ab.b }}
          td.flex.items-center.justify-center
            svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-colors.ease-in-out.duration-200(
              width='12',
              height='16',
              fill='none',
              xmlns='http://www.w3.org/2000/svg',
              @click='(event) => { event.stopPropagation(); deleteCalibration(ab.id, "AB"); }'
            )
              path(
                fill-rule='evenodd',
                clip-rule='evenodd',
                d='M3 1H0v2h12V1H9L8 0H4L3 1zm8 3H1v10a2 2 0 002 2h6a2 2 0 002-2V4z'
              )
</template>

<script>
import { objectsApi } from '@/api'
// import { v4 as uuidv4 } from 'uuid'
export default {
  components: {
    LineChart: () => import('@/utils/LineChart.js')
  },
  props: {
    title: Number,
    param: Object
  },
  created() {
    // this.param.calibrationXYlist.map(element => {
    //  element.id = uuidv4()
    // })
    // this.param.calibrationABlist.map(element => {
    //  element.id = uuidv4()
    // })
    // element.map(v=> ({ ...v, id:uuidv4 }))

    if (this.param.smoothing && this.param.smoothing !== 0)
      this.is_smoothing = true
  },
  data() {
    return {
      isChart: false,
      is_smoothing: false,
      isNewXY: false,
      isNewAB: false,
      isXMissing: false,
      isYMissing: false,
      programaticReset: false,
      newXY: {},
      newAB: {},
      isFromValueMissing: false,
      isAMissing: false,
      isBMissing: false
    }
  },
  computed: {
    chartData() {
      return {
        labels: this.param.calibrationXYlist.map(el => {
          return el.x
        }),
        datasets: [
          {
            borderColor: '#5477A9',
            fill: false,
            backgroundColor: '#5477A9',
            data: this.param.calibrationXYlist.map(el => {
              return el.y
            })
          }
        ]
      }
    }
  },
  methods: {
    calc(formData) {
      objectsApi.universalMethodCalibrations(
        { formData },
        res => {
          this.param.calibrationXYlist = res.data.calibrationXYlist
          this.param.calibrationABlist = res.data.calibrationABlist

          this.isNewXY = false

          this.isNewAB = false
        },
        error => {
          this.$showError(error.response.data.message)
        }
      )
    },
    createXY() {
      this.isNewXY = !this.isNewXY
      this.isNewAB = false
      this.programaticReset = true
      this.newXY = {
        x: '',
        y: ''
      }
      setTimeout(() => (this.programaticReset = false), 100)
    },
    createAB() {
      this.isNewAB = !this.isNewAB
      this.isNewXY = false
      this.programaticReset = true
      this.newAB = {
        fromValue: '',
        a: '',
        b: ''
      }
      setTimeout(() => (this.programaticReset = false), 100)
    },
    validXY() {
      let error = true
      if (this.newXY.x === '') {
        this.isXMissing = true
        error = false
      }
      if (this.newXY.y === '') {
        this.isYMissing = true
        error = false
      }
      return error
    },
    addXY() {
      if (this.validXY()) {
        this.calc([...this.param.calibrationXYlist, this.newXY])
      }
    },
    validAB() {
      let error = true
      if (this.newAB.fromValue === '') {
        this.isFromValueMissing = true
        error = false
      }
      if (!this.newAB.a === '') {
        this.isAMissing = true
        error = false
      }
      if (!this.newAB.b === '') {
        this.isBMissing = true
        error = false
      }
      return error
    },
    addAB() {
      if (this.validAB()) {
        this.calc([...this.param.calibrationABlist, this.newAB])
        this.isNewAB = false
      }
    },
    deleteCalibration(index, calList) {
      if (calList === 'XY') {
        this.param.calibrationXYlist.splice(index, 1)
        this.calc(this.param.calibrationXYlist)
      } else {
        this.param.calibrationABlist.splice(index, 1)
        this.calc(this.param.calibrationABlist)
      }
    },
    save() {
      if (!this.is_smoothing) this.param.smoothing = 0
      this.$emit('save', JSON.parse(JSON.stringify(this.param)))
    }
  },
  watch: {
    'newXY.x': function(val) {
      this.isXMissing = !this.programaticReset && val === ''
    },
    'newXY.y': function(val) {
      this.isYMissing = !this.programaticReset && val === ''
    },
    'newAB.fromValue': function(val) {
      this.isFromValueMissing = !this.programaticReset && val === ''
    },
    'newAB.a': function(val) {
      this.isAMissing = !this.programaticReset && val === ''
    },
    'newAB.b': function(val) {
      this.isBMissing = !this.programaticReset && val === ''
    }
  }
}
</script>
